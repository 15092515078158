@import url(https://fonts.googleapis.com/earlyaccess/nanumgothic.css);

@font-face {
  font-family: "BBTreeGR";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_nine_@1.1/BBTreeGR.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-size: 12px;
  font-family: "NanumGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #343a40;
  display: flex;
  flex: 0;
  width: 100vw;
  -ms-overflow-style: none;

  /* height:100%; */
}
#root {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

p {
  margin: 0;
  padding: 0.1vh 0 0.1vh 0;
  font-size: 0.8rem;
}

printing {
  font-size: 0.7rem;
}

.info {
  padding: 1vh 1vw;
}

.marker {
  margin: 0;
  padding: 0;
  position: absolute;
  max-height: none;
  left: -4px;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.marker_idx {
  z-index: 2;
  width: 24px;
  height: 24px;
  background: #00cf67;
  line-height: 24px;
  border-radius: 12px;
  font-weight: 900;
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
}

.Toastify__toast--default {
  background: #fff;
  color: #777;
  height: 4vh;
}
.Toastify__toast--info {
  /* background: #3498db; */
  background: #fff;
  color: #83be41;
  border: 2px solid #83be41;
  border-radius: 2vh;
  text-align: center;
}
.Toastify__toast--success {
  background: #83be41;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  /* background: #fd4f50;
  //253, 79, 80
  */

  background: #fff;
  color: #83be41;
  border: 2px solid #83be41;
  font-weight: bold;
  border-radius: 2vh;
  text-align: center;
}
.Toastify__toast-body {
  margin: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 767px) {
  @media all and (orientation: landscape) {
    .Toastify__toast-container {
      width: 40vw;
      bottom: 2vh;
    }
  }
  @media all and (orientation: portrait) {
    .Toastify__toast-container {
      width: 100vw;
      height: 4vh;
      max-height: 4vh;
      bottom: 10vh;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
